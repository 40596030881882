<template>
  <div>
    <d-body-top-bar :title="$t('views.shop.title')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.shop.title') }} /
        </label>
        <label
          class="bg-doinsport-primary ml-1"
        >
          {{ $t('views.shop.options.sales') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <list-of-sales
        :selected-sale="selectedSale"
        @on:sales-click="showSaleDetail"
      />
      <side-modal
        :sale="selectedSale"
        :display="display"
        @hidden="onHide"
        @hidden-and-update="display = false;$emit('on:booking-reload')"
      />
    </div>
  </div>
</template>
<script>
import {getSale} from "@/api/doinsport/services/sales/sales.api.js";

export default {
  components: {
    ListOfSales: () => import('./ListOfSales'),
    SideModal: () => import('./SideModal')
  },
  data() {
    return {
      display: false,
      selectedSale: null,
      saleItems: [],
    }
  },
  methods: {
    onHide() {
      this.display = false;
    },
    initTransition () {
      setTimeout(() => {
        this.display = true;
      }, 50);
    },
    showSaleDetail(sale) {
      this.selectedSale = sale;

      this.$nextTick(() => {
        setTimeout(() => {
          this.display = true;
        }, 50);
      });
    }
  }
}
</script>
