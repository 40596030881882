import {clubId, _get, _put} from "@api/doinsport/services/httpService";

const URL = 'clubs/orders';

export const getAllSales = (itemsPerPage, page, search) =>
  _get(
    URL +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search
  )
;

export const getSale = (id) => _get(URL + '/' + id);

export const putSale = (id,data) => _put(URL + '/' + id, data);